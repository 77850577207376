/** ----------------------------------------
    Three JS
---------------------------------------- */

import * as THREE from 'three';


const defaultConfig = {
    position: {
        x: 0,
        y: 0,
        z: 0
    },
    rotation: {
        x: 0,
        y: 0,
        z: 0
    }
}

/** ----------------------------------------
    Device
---------------------------------------- */

class Device {

    constructor() {
        this._model = null;
    }

    /** ----------------------------------------
        Model
    ---------------------------------------- */

    get model() {
        return this._group || this._model;
    }

    /** ----------------------------------------
        Values
    --------------------------------------- */

    values(config) {
        const settings = { ...defaultConfig, ...config };

        Object.keys(settings).forEach(setting => {
            this[setting] = settings[setting];
        });
    }

    /** ----------------------------------------
        Material
    ---------------------------------------- */

    material(obj) {
        if(obj instanceof THREE.Mesh) obj.material.color.setHex(this.color);
    }

}

/** ----------------------------------------
    Exports
---------------------------------------- */

export default Device;