<template>
    <svg width="540px" height="540px" viewBox="0 0 540 540" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="codesandbox" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M124,416 L416,416 L416,124 L124,124 L124,416 Z M386,386 L386,154 L154,154 L154,386 L386,386 Z" id="Shape" fill="#FFFFFF"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Codesandbox-Icon'
    }
</script>