<template>
    <svg width="540px" height="540px" viewBox="0 0 540 540" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="medium" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M299.262967,270.502151 C299.262967,340.917447 242.567375,398 172.633635,398 C102.699894,398 46,340.90454 46,270.502151 C46,200.099762 102.695591,143 172.633635,143 C242.571678,143 299.262967,200.086855 299.262967,270.502151 M438.179642,270.502151 C438.179642,336.782963 409.831847,390.535557 374.862825,390.535557 C339.893803,390.535557 311.546008,336.782963 311.546008,270.502151 C311.546008,204.221339 339.889501,150.468745 374.858523,150.468745 C409.827544,150.468745 438.17534,204.20413 438.17534,270.502151 M495,270.502151 C495,329.873513 485.031592,378.033153 472.731342,378.033153 C460.431091,378.033153 450.466985,329.88642 450.466985,270.502151 C450.466985,211.117882 460.435394,162.971149 472.731342,162.971149 C485.027289,162.971149 495,211.11358 495,270.502151" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Medium-Icon'
    }
</script>