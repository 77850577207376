/** ----------------------------------------
    Packages
---------------------------------------- */

import throttle from 'lodash/throttle';

/** ----------------------------------------
    Size
---------------------------------------- */
 
import { view } from './_view';
import ticker from './_ticker';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _isType from '../check/_isType';

/** ----------------------------------------
    Bounds
 ---------------------------------------- */

const bounds = (el, callback) => {
    let i = 0;

    const viewData = view();
        
    const target = _isType(el) === 'string' ? document.querySelector(el) : el;
    
    return ticker(throttle(() => {
        const bounds = target.getBoundingClientRect();
        const size = Math.min(viewData.height, bounds.height);

        const start = bounds.top - viewData.height;
        const end = bounds.bottom;

        const inView = start < 0 && end > 0;
        
        callback && callback({
            inView: i > 25 && inView,
            start: Math.round(Math.abs(start)), 
            end: Math.round(Math.abs(end)),
            size
        });

        i++;
    }), 500);
};

/** ----------------------------------------
    Export
 ---------------------------------------- */

export default bounds;
