/** ----------------------------------------
    File Name
---------------------------------------- */

const fileName = url => {
    return url.split("/").pop().replace(/\.[^/.]+$/, '');
}

/** ----------------------------------------
    Image
---------------------------------------- */

const image = (url, callback) => {
    const image = new Image();

    image.addEventListener('load', () => {
        callback(url);
    });

    image.src = url; 
};

/** ----------------------------------------
    Video
---------------------------------------- */

const videoConfig = [
    'playsinline',
    'webkit-playsinline',
    'muted',
    'loop',
    'autoplay'
];

const video = (url, callback) => {
    const el = document.createElement('video');

    el.src = url;

    el.id = fileName(url).toLowerCase();

    el.width = 320;
    el.height = 160;

    el.style.opacity = 0;

    for(let config of videoConfig) {
        el[config] = true;
    }

    const app = document.querySelector('#app');
    app.appendChild(el);

    el.addEventListener('loadeddata', () => {
        callback(el);
    });
};

/** ----------------------------------------
    Loader
---------------------------------------- */

const loader = (events, progress) => { 
    let total = events.length - 1;

    const types = {
        image,
        video
    }

    return events.reduce((promise, event, index) => {
        return promise.then(() => {
            const handler = () => {
                return new Promise(resolve => {
                    types[event.type](event.url, resolve);
                    progress && progress((index / total) * 100);
                });
            };

            return Object.keys(types).includes(event.type) && event.url && handler();
        });
    }, Promise.resolve());
};

/** ----------------------------------------
    Exports
---------------------------------------- */

export default loader;