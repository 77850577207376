<template>
	<section class="ethics" id="ideas" ref="section">
        <div class="ethics__container">
            <div class="ethics__grid">
                <div 
                    v-for="(direction, index) in ['odd', 'even']"
                    class="ethics__column"
                    :ref="direction"
                    :class="['ethics__column--' + direction]"
                    :key="index"
                >
                    <EthicsSVG
                        v-if="direction === 'even'" 
                        :class="'ethics__title'"
                    />
                    <InfoCard 
                        v-for="(item, index) in this[direction]"
                        :content="item"
                        ref="items"
                        :key="index"
                    />
                </div>
            </div>
            <div class="ethics__items">
                <InfoCard   
                    v-for="(item, index) in items"
                    class="ethics__item"
                    :content="item" 
                    :key="index"
                />
            </div>
        </div>
    </section>
</template>

<script>
/** ----------------------------------------
    Utils
 ---------------------------------------- */

import { view } from '@/utils/function/_view';
import _bounds from '@/utils/function/_bounds';
import _ticker from '@/utils/function/_ticker';

/** ----------------------------------------
    Templates
 ---------------------------------------- */

import InfoCard from '@/components/cards/Info';

/** ----------------------------------------
    SVG
 ---------------------------------------- */

import EthicsSVG from '@/components/svg/Ethics';

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Section-Ethics',
    components: {
        EthicsSVG,
		InfoCard
    },
    data() {
        return {
            active: false,
            items: [
                {
                    title: 'Focus on outcome',
                    desc: 'I love it when a plan comes together, although things change. I like to stay flexible and focus on outcome over output.'
                },
                {
                    title: 'Walk the talk',
                    desc: 'Been there, done that. Over the years I experienced digital from a broad range of roles, from design, code, strategy, e-commerce and marketing. I do actually know (a lot) of what I’m talking about'
                },
                {
                    title: 'Spark and evolove',
                    desc: 'I love working with likeminded people that share a strong will and focus for digital. Small teams, strong opinions and a drive for results should do the trick.'
                },
                {
                    title: 'Don\'t overthink',
                    desc: 'Don\'t create new problems trying to solve them. A small change can have big impact: valuable is a start, vision is a path. Don\'t overthink and keep it simple. '
                },
                {
                    title: 'No time to waste',
                    desc: 'You don\'t make an impact by talking about it. Competition is fierce, so no time to waste. Let\'s get to it and make a change.'
                }
            ]
        }
    },
    props: {
        wheel: Object
    },
    computed: {
        numbered: function() {
            return this.items.reduce((array, item, index) => {
                item['number'] = index + 1;
                array.push(item);
                return array;
            }, []);
        },
        odd: function() {
            return this.numbered.filter((_, index) => index % 2 === 0);
        },
        even: function() {
            return this.numbered.filter((_, index) => index % 2);
        }
    },
    methods: {
        vertical: function(el, damping = 0.025, direction = 'top') {
            const correction = el.offsetTop;

            const position = (correction - this.wheel.yPos) * damping;
            const movement = position * (direction === 'top' ? -1 : 1);

            el.style.transform = `translate3d(0px, ${ movement }px, 0px)`;
        }
    },
    mounted() {
        const left = this.$refs.odd[0];
        const right = this.$refs.even[0];

        _bounds(left, data => { 
            this.active = data.inView;
        });

        const viewData = view();

        _ticker(() => {
            if(this.active && !viewData.touch) {
                this.vertical(left, 0.03, 'top');
                this.vertical(right, 0.03, 'bottom');
            }
        });
    }
}
</script>