<template>
	<nav class="nav" ref="nav">
        <div class="nav__container">
            <div class="nav__left" ref="left">
                <div class="nav__logo" ref="logo">
                    SANDER HIDDING
                    PORTFOLIO <span>©{{ year }}</span>
                </div>
                <div class="nav__activity" ref="activity">
                    {{ time }} — {{ work }}
                </div>
            </div>
            <div class="nav__right" ref="right">
                <ul class="nav__list">
                    <li 
                        class="nav__item"
                        v-for="(item, index) in list" 
                        data-action="enlarge"
                        ref="item"
                        :key="index"
                    >
                        <a 
                            class="nav__link" 
                            :href="id(item)"
                            @mouseover="mouseOver(item, $event)"
                            @mouseleave="mouseLeave(item, $event)"
                            @click="navigate(item, offset(index), $event)"
                        >
                            <div class="nav__letters">
                                <span 
                                    class="nav__letter"
                                    v-for="(letter, index) in split(item)"
                                    :key="index"
                                >
                                    {{ letter }}
                                </span>
                            </div>
                            <div class="nav__clones">
                                <span 
                                    class="nav__clone"
                                    v-for="(letter, index) in split(item)"
                                    :key="index"
                                >
                                    {{ letter }}
                                </span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
/** ----------------------------------------
    Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _toKebab from '@/utils/format/_toKebab';
import _random from '@/utils/function/_random';
import { view } from '@/utils/function/_view';


const reveal = els => {
    const { item, logo } = els;

    anime({
        targets: [logo, ...item],
        translateY: ['-300%', '0%'],
        opacity: [0, 1],
        duration: 1100,
        delay: anime.stagger(35),
        easing: 'easeOutExpo'
    });
}

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Layout-Navigation',
    data() {
        return {
            active: null,
            list: [
                'about',
                'work',
                'ideas',
                'contact',
            ]
        }
    },
    props: {
        load: {
            type: Boolean,
            default: false,
        },
        atTop: Boolean,
        atBottom: Boolean,
        scrolling: Boolean,
        wheel: Object
    },
    watch: {
        load() {
            reveal(this.$refs);
        },
        atTop() {
            this.hideActivity();
        }
    },
    computed: {
        time: function() {
            const date = this.date;
            let hours = date.getHours();

            const notation = hours >= 12 ? 'pm' : 'am';

            hours = hours % 12;
            hours = hours ? hours : 12;

            return `${ hours } ${ notation.toUpperCase() }`;
        },
        date: function() {
            const date = new Date();

            date.toLocaleString('nl-NL', { 
                timeZone: 'Europe/Amsterdam' 
            });

            return date;
        },
        year: function() {
            return this.date.getFullYear();
        },
        work: function() {
            const date = this.date;

            const time = date.getHours();
            const dayOfWeek = date.getDay();

            const between = (start, end) => time > start && time <= end;

            const free = ['Music playing', 'Streaming', 'Reading', 'Study']

            if(dayOfWeek === 0 || dayOfWeek === 6) return 'Weekend';
            if(between(7, 8)) return 'Commuting';
            if(between(8, 18)) return 'Solving puzzles';
            if(between(18, 19)) return 'Diner is served';
            if(between(19, 24)) return _random(free);
            if(between(0, 7)) return 'Sleeping';

            return 'Solving puzzles';
        }
    },
    methods: {
        id: name => {
            return '#' + _toKebab(name);
        },
        offset: index => {
            if(index >= 1) return true;
        },
        split: string => {
            return string.split('');
        },
        navigate: function(name, offset, e) {
            e.preventDefault(); 

            const viewData = view();

            const id = this.id(name);
            const el = document.querySelector(id);

            const bounds = el.getBoundingClientRect();
            const correction = offset ? viewData.height * 0.25 : 0;

            const pos = bounds.top - correction;

            this.wheel.moveTo(pos);
        },
        hideActivity: function() {
            anime({
                targets: this.$refs.activity,
                translateY: this.atTop ? ['-300%', '0%'] : ['0%', '-300%'],
                opacity: this.atTop ? [0, 1] : [1, 0],
                duration: 1500,
                easing: 'easeOutExpo'
            });
        },
        mouseOver: function(name, e) {
            const el = e.target;

            const letters = el.querySelectorAll('.nav__letter');
            const clones = el.querySelectorAll('.nav__clone');

            if(!this.active || this.active !== name) {
                this.active = name;

                anime({
                    targets: letters,
                    translateY: ['0em', '-1em'],
                    duration: 350,
                    delay: anime.stagger(30),
                    easing: 'easeOutQuad'
                });

                anime({
                    targets: clones,
                    translateY: ['1em', '0'],
                    duration: 425,
                    delay: anime.stagger(35),
                    easing: 'easeOutQuad'
                });
            }
        },
        mouseLeave: function() {
            this.active = null;
        }
    }
}
</script>