/** ----------------------------------------
    Random
---------------------------------------- */

const random = array => array[Math.floor(Math.random() * array.length)];

/** ----------------------------------------
    Export
---------------------------------------- */

export default random;