<template>
    <svg width="540px" height="540px" viewBox="0 0 540 540" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="linkedin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M432,412 L365.396104,412 L365.396104,298.408979 C365.396104,267.265438 353.577922,249.861385 328.960227,249.861385 C302.179383,249.861385 288.1875,267.973006 288.1875,298.408979 L288.1875,412 L224,412 L224,195.611005 L288.1875,195.611005 L288.1875,224.758572 C288.1875,224.758572 307.487013,189 353.345779,189 C399.183442,189 432,217.02813 432,274.99588 L432,412 Z M147.505266,168 C125.683109,168 108,150.089394 108,128 C108,105.910606 125.683109,88 147.505266,88 C169.327423,88 187,105.910606 187,128 C187,150.089394 169.327423,168 147.505266,168 Z M114,412 L181,412 L181,196 L114,196 L114,412 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Linkedin-Icon'
    }
</script>