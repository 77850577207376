<template>
    <div 
        class="card-info"
        data-action="black"
    >
        <h3 class="card-info__title">
            <span class="badge card-info__badge">→</span>
            {{ content.number }}. {{ content.title }}
        </h3>
        <p class="card-info__desc">{{ content.desc }}</p>
    </div>
</template>

<script>
/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Card-Info',
    props: {
        content: null
    }
}
</script>