/** ----------------------------------------
    Ticker
 ---------------------------------------- */

/**
 * The ticker will execute 60 frames per
 * second to update the canvas. 
 *
 * This ticker is inspired by
 *
 * @author      https://www.growingwiththeweb.com/2017/12/fast-simple-js-fps-counter.html
 */

let fps = null;
let tick = 0;

const times = [];
 
const ticker = script => {
    let animation = null;

    window.requestAnimationFrame(() => {
        const now = performance.now();

        while (times.length > 0 && times[0] <= now - 1000) times.shift();

        times.push(now); 
        fps = times.length;
        tick++;

        animation = ticker(script && script(tick) || script);
    });

    return animation;
};
 
 /** ----------------------------------------
     Exports
  ---------------------------------------- */
 
 export default ticker;