/** ----------------------------------------
    Three JS
---------------------------------------- */

import * as THREE from 'three';

/** ----------------------------------------
    Three JS Loader
---------------------------------------- */

import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

/** ----------------------------------------
    Base
---------------------------------------- */

import Device from './Device';

/** ----------------------------------------
    Utils
---------------------------------------- */

import roundedPlane from '../utils/rounded';

/** ----------------------------------------
    Device
---------------------------------------- */

class Phone extends Device {

    constructor(config) {
        super();

        this.values(config);

        this.create();
    }

    /** ----------------------------------------
        Group
    ---------------------------------------- */

    group(mesh) {
        if(!this._group) this._group = new THREE.Group();
        this._group.add(mesh);
    }

    /** ----------------------------------------
        Loader
    ---------------------------------------- */

    loader(obj) {
        const pos = this.position;
        const rotate = this.rotation;

        obj.traverse(this.material.bind(this));

        obj.position.set(pos.z, pos.y, pos.x);
        obj.rotation.set(rotate.z, rotate.y, rotate.x);
        obj.receiveShadow = this.shadow;

        this.group(obj);
    }

    screen() {
        let geometry = roundedPlane({
            width: 1.9, 
            height: 3.8, 
            radius: 0.15
        });

        const texture = new THREE.TextureLoader()
            .load('./media/screenshot.png', texture => {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.repeat.set(0.5275, 0.2625);
            });
        
        const material = new THREE.MeshBasicMaterial({ 
            map: texture,
            needsUpdate: true
        });

        const plane = new THREE.Mesh(geometry, material);

        plane.position.set(0.05, -1.925, 0.925);
        plane.rotation.set(0, Math.PI * 0.5, 0);

        this.group(plane);
    }

    /** ----------------------------------------
        Create
    ---------------------------------------- */

    create() {
        const loader = new OBJLoader();
        loader.load('./models/phone.obj', this.loader.bind(this));
        
        this.screen();

        this.scene.add(this.model);
    }

}

/** ----------------------------------------
    Exports
---------------------------------------- */

export default Phone;