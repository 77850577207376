/** ----------------------------------------
    Packages
---------------------------------------- */

import throttle from 'lodash/throttle';

/** ----------------------------------------
    Mouse
---------------------------------------- */

const mouse = callback => {

    if(window === 'undefined') return;

    /** ----------------------------------------
        Mouse Values
    ---------------------------------------- */

    const data = {
        active: false,
        x: window && window.innerWidth,
        y: window && window.innerHeight,
        centerX: 0,
        centerY: 0,
        lastX: 0,
        lastY: 0,
        lastVx: 0,
        lastVy: 0,
        accX: 0,
        accY: 0,
        velX: 0,
        velY: 0,
        direction: {},
        time: 0,
        moving: false,
        hold: false,
        target: {},
        el: null,
        action: null,
        type: null
    };

    /** ----------------------------------------
        Mouse Settings
    ---------------------------------------- */

    let int, now = 0;

    const mouseSettings = e => {
        if(!e) e = window.event;
        data.target = e.target;
        if(data.time === 0) data.time = Date.now();
        now = Date.now(); 
        int = now - data.time;
    };

    /** ----------------------------------------
        Set Mouse Position
    ---------------------------------------- */

    const setMouse = e => {
        if (e.pageX || e.pageY) {
            data.x = e.pageX;
            data.y = e.pageY;
        }

        if (e.clientX || e.clientY) {
            data.x = (e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft);
            data.y = (e.clientY + document.body.scrollTop + document.documentElement.scrollTop); 
        }
    };

    /** ----------------------------------------
        Set Mouse Center Position
    ---------------------------------------- */

    const setCenterMouse = () => {
        data.centerX = window.innerWidth * 0.5 - data.x;
        data.centerY = window.innerHeight * 0.5 - data.y;
    };

    /** ----------------------------------------
        Last Mouse Position
    ---------------------------------------- */

    const mouseLast = () => {
        data.lastX = data.x;
        data.lastY = data.y;
    };

    /** ----------------------------------------
        Mouse Delta
    ---------------------------------------- */

    const mouseDelta = () => {
        data.dx = data.x - data.lastX;
        data.dy = data.y - data.lastY;
    };

    /** ----------------------------------------
        Mouse Direction
    ---------------------------------------- */

    const mouseDirection = () => {
        const top = Math.sign(data.dy) === -1;
        const bottom = Math.sign(data.dy) === 1;
        const left = Math.sign(data.dx) === -1;
        const right = Math.sign(data.dx) === 1;

        data.direction.top = top;
        data.direction.bottom = bottom;
        data.direction.left = left;
        data.direction.right = right;
    };

    /** ----------------------------------------
        Mouse Still
    ---------------------------------------- */

    const mouseStill  = () => {
        if (data.x === data.lastX && data.y === data.lastY) {
            Object.keys(data.direction).map(pos => {
                data.direction[pos] = false;
            });
        }
    };

    /** ----------------------------------------
        Mouse Velocity
    ---------------------------------------- */

    const mouseVelocity = () => {
        data.velX = Math.round(data.dx / int);
        data.velY = Math.round(data.dy / int);
    };

    /** ----------------------------------------
        Mouse Acceleration
    ---------------------------------------- */

    const mouseAcceleration = () => {
        const ax = data.velX - data.lastVx;
        const ay = data.velY - data.lastVy;

        data.accX = Math.round(ax / int * 10);
        data.accY = Math.round(ay / int * 10);

        if(!isFinite(data.accX)) data.accX = 0;
        if(!isFinite(data.accY)) data.accY = 0;
    };

    /** ----------------------------------------
        Mouse History
    ---------------------------------------- */

    const mouseHistory = () => {
        data.lastVx = data.velX;
        data.lastVy = data.velY;
        data.time = now;
    };

    /** ----------------------------------------
        Mouse Events
    ---------------------------------------- */

    window.addEventListener('mousemove', throttle(e => {

        /** ----------------------------------------
            Mouse Configuration
        ---------------------------------------- */

        mouseSettings(e);
        mouseLast();
        setMouse(e);
        setCenterMouse();

        /** ----------------------------------------
            Mouse Values
        ---------------------------------------- */

        mouseDelta(); 
        mouseDirection();
        mouseStill();
        mouseVelocity();
        mouseAcceleration();
        mouseHistory();

        /** ----------------------------------------
            Mouse Callback
        ---------------------------------------- */

        callback && callback(data, e);

    }, 10));

    /** ----------------------------------------
        Mouse Target
    ---------------------------------------- */

    const mouseTarget = e => {
        const el = e.target;
        const parent = el.parentNode;
        const grand = parent.parentNode;

        if(!el || !parent || !grand) return;

        const action = el.dataset.action || parent.dataset.action || grand.dataset.action;
        data.action = action;

        data.el = action && (el || parent || grand);

        if(data.target !== el && action && action !== data.type) {
            data.target = action && (el || parent || grand);
            data.type = action;
        }
    };

    window.addEventListener('mouseover', throttle(mouseTarget, 10));

    setInterval(() => {
        if(((Math.abs(data.dx) > 1 && Math.abs(data.dy) > 1)) && !data.action) {
            data.target = null;
            data.type = null;
        }
    }, 50);

    /** ----------------------------------------
        Mouse View
    ---------------------------------------- */

    document.addEventListener('mouseenter', throttle(() => {
        data.active = true;
    }, 10));

    document.addEventListener('mouseleave', throttle(() => {
        data.active = false;
    }, 10));

    /** ----------------------------------------
        Mouse Clicked
    ---------------------------------------- */

    document.addEventListener('mousedown', () => {
        data.hold = true;
    });

    document.addEventListener('mouseup', () => {
        data.hold = false;
    });

    /** ----------------------------------------
        Mouse Data
    ---------------------------------------- */

    return data;

};

/** ----------------------------------------
    Export
---------------------------------------- */

export default mouse;