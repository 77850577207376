/** ----------------------------------------
    View
---------------------------------------- */

const view = callback => {

    if(window === 'undefined') return;

    /** ----------------------------------------
        View Values
    ---------------------------------------- */

    const data = {
        width: 0,
        height: 0,
        centerX: 0,
        centerY: 0,
        posX: 0,
        posY: 0,
        touch: false
    };

    /** ----------------------------------------
        View Size
    ---------------------------------------- */

    const viewSize = () => {
        data.width = window.innerWidth;
        data.height = window.innerHeight;
    };

    viewSize();

    /** ----------------------------------------
        View Center
    ---------------------------------------- */

    const viewCenter = () => {
        data.centerX = data.width * 0.5;
        data.centerY = data.height * 0.5;
    };

    viewCenter();

     /** ----------------------------------------
        View Size
    ---------------------------------------- */

    const viewPos = () => {
        data.posX = window.screenX;
        data.posY = window.screenY;
    };

    viewPos();

    /** ----------------------------------------
        View Touch
    ---------------------------------------- */

    const viewTouch = () => {
        return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
    }

    viewTouch();

    /** ----------------------------------------
        View Events
    ---------------------------------------- */

    window.addEventListener('resize', () => {

        /** ----------------------------------------
            View
        ---------------------------------------- */

        viewSize();
        viewCenter();

        /** ----------------------------------------
            View Callback
        ---------------------------------------- */

        callback && callback(data);

    });

    return data;

};

/** ----------------------------------------
    Elements
---------------------------------------- */

const html = document.documentElement;
const body = document.body;

/** ----------------------------------------
    Page
---------------------------------------- */

const page = callback => {

    if(document === 'undefined') return;
    
    /** ----------------------------------------
        Page Values
    ---------------------------------------- */

    const data = {
        width: 0,
        height: 0,
        centerX: 0,
        centerY: 0
    };

    /** ----------------------------------------
        Page Size
    ---------------------------------------- */

    const pageSize = () => {
        let height, width;

        const container = document.querySelector('.scroll__container');

        if(!container) {
            height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
        }

        if(container) {
            height = container.offsetHeight;
            width = container.offsetWidth;

            height += parseInt(window.getComputedStyle(container).getPropertyValue('margin-top'));
            height += parseInt(window.getComputedStyle(container).getPropertyValue('margin-bottom'));
        }

        data.width = width;
        data.height = height;
    };

    pageSize();

    /** ----------------------------------------
        Screen Center
    ---------------------------------------- */

    const pageCenter = () => {
        data.centerX = data.width * 0.5;
        data.centerY = data.height * 0.5;
    };

    pageCenter();

    /** ----------------------------------------
        Page Events
    ---------------------------------------- */

    window.addEventListener('resize', () => {

        /** ----------------------------------------
            Page
        ---------------------------------------- */

        pageSize();
        pageCenter();

        /** ----------------------------------------
            Page Callback
        ---------------------------------------- */

        callback && callback(data);

    });

    return data;

};

/** ----------------------------------------
    Export
 ---------------------------------------- */

export { page, view };