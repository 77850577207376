/** ----------------------------------------
    Events
 ---------------------------------------- */

import mouse from '../utils/function/_mouse';
import ticker from '../utils/function/_ticker';
import wheel from '../utils/function/_wheel';

/** ----------------------------------------
    Window
 ---------------------------------------- */

import { view } from '../utils/function/_view';
const viewData = view();

/** ----------------------------------------
    Event Mixin
 ---------------------------------------- */

const EventMixin = {
    data() {
		return {
            mouse: {
                x: 0,
                y: 0,
                xPos: viewData.centerX,
                yPos: viewData.centerY
            },
            wheel: {
                x: 0,
                y: 0,
                xPos: 0,
                yPos: 0
            }
		}
	},
    mounted() {
        const mouseEvent = mouse();
		const wheelEvent = wheel();

        this.wheel.moveTo = wheelEvent.moveToY;

        ticker(() => {
            this.mouse.x = mouseEvent.x;
            this.mouse.y = mouseEvent.y;

            this.mouse.xPos += (mouseEvent.x - this.mouse.xPos - (mouseEvent.accX * 0.5)) * 0.1;
            this.mouse.yPos += (mouseEvent.y - this.mouse.yPos - (mouseEvent.accY * 0.5)) * 0.1;
            
            this.mouse.active = mouseEvent.active;
            this.mouse.action = mouseEvent.type;
            
            this.mouse.el = mouseEvent.el;
            
            this.wheel.x = wheelEvent.x;
            this.wheel.y = wheelEvent.y;
            
            this.wheel.xPos += (wheelEvent.x - this.wheel.xPos - (wheelEvent.accX * 0.5)) * 0.1;
            this.wheel.yPos += (wheelEvent.y - this.wheel.yPos - (wheelEvent.accY * 0.5)) * 0.1;

            this.wheel.scrolling = wheelEvent.scrolling();

            this.wheel.atTop = wheelEvent.atTop;
            this.wheel.atBottom = wheelEvent.atBottom;
        });
	}
};

export default EventMixin;