<template>
	<div 
        class="cursor" 
        ref="cursor"
        :class="[mouse.action && ('is-' + mouse.action)]"
    />
    <div 
        class="dot" 
        ref="dot"
        :class="[mouse.action && ('is-' + mouse.action)]"
    />
</template>

<script>
/** ----------------------------------------
    Mixin
 ---------------------------------------- */

import anime from 'animejs';
import EventMixin from '@/mixins/Event';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import device from '@/utils/function/_device';
import ticker from '@/utils/function/_ticker';

/** ----------------------------------------
    Reveal
 ---------------------------------------- */

const reveal = els => {
    const { cursor, dot } = els;

    anime({
        targets: [cursor, dot],
        opacity: [0, 1],
        duration: 500,
        easing: 'easeOutQuad',
        complete: () => {
            cursor.style.opacity = null; 
        }
    });
}

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Layout-Cursor',
	mixins: [
		EventMixin
	],
    props: {
        load: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        load() {
            reveal(this.$refs);
        }
    },
    methods: {
        locked: function(el) {
            if(!el) return;

            const width = el.offsetWidth;
            const height = el.offsetHeight;

            return {
                centerX: width * 0.5,
                centerY: height * 0.5,
            }
        }
    },
	mounted() {
        let locked = false;

		const cursor = this.$refs.cursor;

        const cursorWidth = cursor.offsetWidth;
        const cursorHeight = cursor.offsetHeight;

        const dot = this.$refs.dot;

        const dotWidth = dot.offsetWidth;
        const dotHeight = dot.offsetHeight;

        const _device = device();

        if(_device === 'desktop') {
            ticker(() => {
                if(this.mouse.action !== 'lock') {
                    cursor.style.top = (this.mouse.yPos - cursorHeight * 0.5) + 'px';
                    cursor.style.left = (this.mouse.xPos - cursorWidth * 0.5) + 'px';

                    locked = false;
                }

                if(this.mouse.action === 'lock' && !locked) {
                    const el = this.locked(this.mouse.el);
                    const bounds = this.mouse.el.getBoundingClientRect();

                    cursor.style.top = el.centerY + bounds.top - 26 + 'px';
                    cursor.style.left = el.centerX + bounds.left - 26 + 'px';

                    locked = true;
                }
                
                dot.style.transform = `translate3d(${ this.mouse.x - dotWidth * 0.5 }px, ${ this.mouse.y - dotHeight * 0.5 }px, 0px)`;
            });
        } 

        if(_device !== 'desktop') {
            dot.remove();
            cursor.remove();
        }
	}
}
</script>