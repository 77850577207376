<template>
	<section class="info" id="about" ref="section">
        <div class="info__container">
            <div class="info__figure" ref="figure">
                <div class="info__thumb" data-action="blend">
                    <img src="@/assets/images/picture-green.png" class="info__image" ref="image">
                </div>
                <!-- <caption class="info__caption">📸 &nbsp; by Pim van Boesschoten for Greenberry</caption> -->
            </div>
            <div class="info__content">
                <p class="info__circular" ref="circular" />
                <h1 class="info__title" ref="title">I find and solve problems in the real world with digital products</h1>
                <p class="info__text" ref="text">...while keeping ambitions, goals and simplicity in mind. Always with a no-nonsense mentality and a honest sense of humor.</p>
                <a 
                    class="info__scroll" 
                    data-action="pulse" 
                    ref="scroll" 
                    @click="navigate($event)"
                >
                    <span class="info__more">
                        <span class="info__more--original">Scroll for more</span>
                        <span class="info__more--clone">Scroll for more</span>
                    </span> 
                    <span class="header__arrow">↕</span>
                </a>
            </div>
        </div>
        <Marquee 
            ref="marquee"
            :class="'info__marquee'"
            :text="'Hidding Sander'" 
            :wheel="wheel"
        />
    </section>
</template>

<script>
/** ----------------------------------------
    Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _bounds from '../utils/function/_bounds';
import _circular from '../utils/function/_circular';
import _ticker from '../utils/function/_ticker';
import { view } from '../utils/function/_view';
import _wrapWords from '../utils/function/_wrapWords';

/** ----------------------------------------
    Components
 ---------------------------------------- */

import Marquee from './Marquee.vue';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import device from '@/utils/function/_device';

/** ----------------------------------------
    Reveal
 ---------------------------------------- */

const reveal = els => {
    const { figure, marquee, text, title } = els;

    anime({
        targets: figure,
        opacity: [0, 1],
        top: [125, 0],
        duration: 1500,
        easing: 'easeOutExpo'
    });

    anime({
        targets: marquee.$el,
        opacity: [0, 1],
        translateX: ['5vw', '0vw'],
        delay: 250,
        duration: 750,
        easing: 'easeOutQuad'
    });

    anime({
        targets: title.querySelectorAll('span'),
        opacity: [0, 1],
        translateX: [75, 0],
        translateY: [100, 0],
        rotate: [5, 0],
        delay: anime.stagger(50, {
            start: 500,
        }),
        duration: 1000,
        easing: 'easeOutExpo'
    });

    anime({
        targets: text,
        opacity: [0, 0.5],
        translateY: [25, 0],
        delay: 1250,
        duration: 350,
        easing: 'easeOutQuad'
    });
};

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Section-Info',
    components: {
        Marquee,
    },
    data() {
        return {
            active: true,
            completed: false,
            reveal: false
        }
    },
    props: {
        load: {
            type: Boolean,
            default: false
        },
        mouse: Object,
        wheel: Object
    },
    watch: {
        load() {
            reveal(this.$refs);
        }
    },
    methods: {
        navigate: function(e) {
            e.preventDefault(); 

            const viewData = view();

            const el = document.querySelector('#work');

            const bounds = el && el.getBoundingClientRect();

            const correction = viewData.height * 0.25;
            const pos = bounds.top - correction;

            this.wheel.moveTo(pos);
        },
        rotate: function(el) {
            el.style.transform = `translate(${ (this.mouse.xPos * 0.02)}px, ${ this.wheel.yPos * -0.1 }px) rotate(${ (this.mouse.xPos * 0) }deg)`;
        }
    },
    mounted() {
        const el = this.$refs.section;
        const circular = this.$refs.circular;
        const figure = this.$refs.figure;
        const title = this.$refs.title;

        _wrapWords(title);

        _bounds(circular, data => {
            this.reveal = data.inView;
        });

        _bounds(el, data => {
            this.active = data.inView;
        });

        anime({
            targets: circular,
            rotate: [0, 360],
            duration: 10000,
            loop: true,
            easing: 'linear',
        });

        const letters = _circular(circular, 'I\'m a digital strategist from Deventer ', 275);

        anime({
            targets: letters.children,
            scale: [1, 1.25],
            opacity: [0, 1],
            translateY: [30, 0],
            duration: 450,
            delay: anime.stagger(35, {
                start: 4250
            }), 
            easing: 'easeOutQuad'
        });

        if(device() === 'desktop') {
            _ticker(() => {
                if(this.active) {
                    this.rotate(figure);
                }
            });
        }
    }
}
</script>