/** ----------------------------------------
    Utils
---------------------------------------- */

import { page, view } from './_view';

/** ----------------------------------------
    Wheel
---------------------------------------- */

const wheel = callback => {

    if(window === 'undefined') return;

    /** ----------------------------------------
        Page Values
    ---------------------------------------- */

    const pageData = page();
    const viewData = view();

    /** ----------------------------------------
        Wheel Values
    ---------------------------------------- */

    const data = {
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0,
        dx: 0,
        dy: 0,
        lastVx: 0,
        lastVy: 0,
        accX: 0,
        accY: 0,
        velX: 0,
        velY: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        time: 0,
        atTop: true,
        atBottom: false,
        scrolling: () => data.velX !== 0 || data.velY !== 0,
        moveToX: value => data.x += value,
        moveToY: value => data.y += value,       
        direction: {}
    };

    /** ----------------------------------------
        Wheel Settings
    ---------------------------------------- */

    let int, now = 0;

    const wheelSettings = e => {
        if(!e) e = window.event;
        data.target = e.target;
        if(data.time === 0) data.time = Date.now();
        now = Date.now(); 
        int = now - data.time;
    };

    /** ----------------------------------------
        Wheel Position
    ---------------------------------------- */

    const wheelPosition = e => {
        data.x += e.deltaX;
        data.y += e.deltaY;
    };

    /** ----------------------------------------
        Last Wheel Position
    ---------------------------------------- */

    const wheelLast = () => {
        data.lastX = data.x;
        data.lastY = data.y;
    };

    /** ----------------------------------------
        Wheel Delta
    ---------------------------------------- */

    const wheelDelta = () => {
        data.dx = data.x - data.lastX;
        data.dy = data.y - data.lastY;
    };

    /** ----------------------------------------
        Wheel Direction
    ---------------------------------------- */

    const wheelDirection = () => {
        const top = Math.sign(data.dy) === -1;
        const bottom = Math.sign(data.dy) === 1;
        const left = Math.sign(data.dx) === -1;
        const right = Math.sign(data.dx) === 1;

        data.direction.top = top;
        data.direction.bottom = bottom;
        data.direction.left = left;
        data.direction.right = right;
    };

    /** ----------------------------------------
        Wheel Limit
    ---------------------------------------- */

    const wheelLimit = () => {
        data.atTop = data.top === 0;
        data.atBottom = data.bottom === 0;
    };

    /** ----------------------------------------
        Wheel Velocity
    ---------------------------------------- */

    const wheelVelocity = () => {
        data.velX = Math.round(data.dx / int);
        data.velY = Math.round(data.dy / int);
    };

    /** ----------------------------------------
        Wheel Acceleration
    ---------------------------------------- */

    const wheelAcceleration = () => {
        const ax = data.velX - data.lastVx;
        const ay = data.velY - data.lastVy;

        data.accX = Math.round(ax / int * 100);
        data.accY = Math.round(ay / int * 100);

        if(!isFinite(data.accX)) data.accX = 0;
        if(!isFinite(data.accY)) data.accY = 0;
    };

    /** ----------------------------------------
        Wheel History
    ---------------------------------------- */

    const wheelHistory = () => {
        data.lastVx = data.velX;
        data.lastVy = data.velY;
        data.time = now;
    };

    /** ----------------------------------------
        Wheel Limit
    ---------------------------------------- */

    const wheelBorder = () => {
        data.atTop = false;
        data.atBottom = false;

        if(data.y < 0) data.y = 1;
        if(data.y === 1) data.atTop = true;

        const height = pageData.height - (viewData.height * 0.8);

        if(data.y > height) data.y = height;
        if(data.y === height) data.atBottom = true;
    };

    /** ----------------------------------------
        Wheel Events
    ---------------------------------------- */

    window.addEventListener('wheel', e => {

        /** ----------------------------------------
            Wheel Configuration
        ---------------------------------------- */

        wheelSettings(e);
        wheelLast();
        wheelPosition(e);

        /** ----------------------------------------
            Wheel Values
        ---------------------------------------- */

        wheelDelta();
        wheelDirection();
        wheelLimit();
        wheelVelocity();
        wheelAcceleration();
        wheelHistory();
        wheelBorder();

         /** ----------------------------------------
            wheel Callback
        ---------------------------------------- */

        callback && callback(data);

    });

    window.addEventListener('resize', () => {
        wheelLimit();
        wheelBorder();
    });

    return data;

};

/** ----------------------------------------
    Export
 ---------------------------------------- */

export default wheel;