<template>
    <div class="loader" data-action="enlarge">
        <div class="loader__logo" ref="logo">
            <div class="loader__logo--top" ref="top">
                <span class="loader__text">SANDER HIDDING</span>
            </div>
            <div class="loader__logo--bottom" ref="bottom">
                <span class="loader__text">PORTFOLIO 
                    <span class="loader__year">©{{ year }}</span>
                </span>
            </div>
            <div class="loader__info">
                <span class="loader__text">
                    {{ info }}
                </span>
            </div>
        </div>
    </div>
    <!-- <div class="loader__background" ref="background" /> -->
    <slot />
</template>

<script>
/** ----------------------------------------
    Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _loader from '@/utils/function/_loader';

/** ---------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Layout-Loader',
    data() {
        return {
            info: '0%',
            load: true
        }
    },
    emits: {
        load() {
            return true;
        }
    },
    watch: {
        load() {
            const logo = this.$refs.logo;

            anime({
                targets: logo,
                opacity: [1, 0]
            });
        }
    },
    computed: {
        date: function() {
            const date = new Date();

            date.toLocaleString('nl-NL', { 
                timeZone: 'Europe/Amsterdam' 
            });

            return date;
        },
        year: function() {
            return this.date.getFullYear();
        }
    },
    methods: {
        reveal: function() {
            const text = this.$refs.logo.querySelectorAll('.loader__text');

            anime({
                targets: text,
                translateY: ['100%', '100%', '0%', '0%', '-110%'],
                opacity: [0, 1, 1, 1, 1],
                duration: 3250,
                delay: anime.stagger(150),
                easing: 'easeInOutCirc',
                complete: () => {
                    this.$refs.logo.remove();
                }
            });

            anime({
                delay: 500,
                duration: 3500,
                easing: 'easeOutExpo',
                update: animation => {
                    this.info = `Loading ${ animation.progress.toFixed(2) }%`;
                }
            });
        },
        unix: function() {
            return Math.floor(new Date().getTime() / 1000);
        }
    },  
    mounted() {
        _loader([
            {
                type: 'image',
                url: require('../assets/images/picture-green.png')
            },
            {
                type: 'image',
                url: require('../assets/images/mocks.png')
            },
            {
                type: 'video',
                url: 'media/builders.mp4'
            },
            {
                type: 'video',
                url: 'media/fojm.mp4'
            }
        ]).then(() => {
            setTimeout(() => {
                this.$emit('load');
                this.load = false;
            }, 3500);
        });

        this.reveal();

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 1000)
    }
}
</script>