/* ----------------------------------------
    Device
---------------------------------------- */

const device = () => {
    const agent = navigator.userAgent;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(agent)) return 'tablet';
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(agent)) return 'mobile';

    return 'desktop';
};

/* ----------------------------------------
    Export
---------------------------------------- */

export default device;