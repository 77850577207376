<template>
	<footer class="footer">
        <div class="corner corner--bottom-left footer__corner--left" />
        <div class="corner corner--bottom-right footer__corner--right" />

        <div class="footer__container">
            <h3 class="footer__heading">Find me online, connect in person</h3>
            <div class="footer__socials">
                <a
                    v-for="(item, index) in socials"
                    class="footer__link footer__link--social"
                    data-action="simple"
                    target="_blank"
                    :href="item.url"
                    :key="index"
                >
                    <component v-bind:is="item.icon" />
                    <span class="footer__desc">{{ item.name }}</span>
                </a>
            </div>
            <p class="footer__disclaimer">Send a mail <a href="mailto:to@sanderhidding.nl" data-action="visit">to@sanderhidding.nl</a>.</p>
            <p class="footer__disclaimer footer--blend">This website was created with the help of Vue x Three JS. </p>
            <p class="footer__disclaimer footer--green">v.3.1.0 created in 2022: latest update {{ updated }} ago.</p>
        </div>
    </footer>
</template>

<script>
/** ----------------------------------------
    Modules
 ---------------------------------------- */

import moment from 'moment';

/** ----------------------------------------
    Icons
 ---------------------------------------- */

import Codesandbox from '@/components/icons/Codesandbox';
import Github from '@/components/icons/Github';
import Linkedin from '@/components/icons/Linkedin';
import Medium from '@/components/icons/Medium';
import Spotify from '@/components/icons/Spotify';

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Layout-Footer',
    components: {
		Codesandbox,
        Github,
        Linkedin,
        Medium,
        Spotify
    },
    data() {
        return {
            socials: [
                {
                    name: 'Linkedin',
                    icon: Linkedin,
                    url: 'https://www.linkedin.com/in/sanderhidding/'
                },
                { 
                    name: 'Medium',
                    icon: Medium,
                    url: 'https://medium.com/@sander_hidding'
                },
                {
                    name: 'Spotify',
                    icon: Spotify,
                    url: 'https://open.spotify.com/user/119644496'
                },
                {
                    name: 'Github',
                    icon: Github,
                    url: 'https://github.com/waxs'
                }
            ]
        }
    },
    computed: {
        updated: function() {
            const date = new Date();
            const now = Math.floor(date.getTime() * 0.001)
            return moment.duration(1664820320 - now, 'seconds').humanize();
        }
    }
}
</script>