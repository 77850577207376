/** ----------------------------------------
    Utils
---------------------------------------- */

import _isType from '../check/_isType'; 

/** ----------------------------------------
    To Kebab
---------------------------------------- */

const toKebab = string => {
    if(_isType(string) === 'string') {
        return string.replace(/([a-z])([A-Z])/g, '$1-$2')
            .replace(/\s+/g, '-')
            .toLowerCase();
    }
};

/** ----------------------------------------
    Export
---------------------------------------- */

export default toKebab;