/** ----------------------------------------
    Three JS
 ---------------------------------------- */

 import * as THREE from 'three';

 /** ----------------------------------------
     Ambient
  ---------------------------------------- */
 
 class Point {
 
     constructor(config) {
         this.values(config);
         this.create();
         this.apply();
         this.helper();
     }
 
     /** ----------------------------------------
         Values
      ---------------------------------------- */
 
     values(config) {
         Object.keys(config).forEach(setting => {
             this[setting] = config[setting];
         });
     }
 
     /** ----------------------------------------
         Apply
      ---------------------------------------- */
 
     apply() {
         const pos = this.position;
 
         this.light.position.set(pos.x, pos.y, pos.z);
         this.light.intensity = this.intensity;
         this.light.castShadow = this.shadow;
 
         this.scene.add(this.light);
     }
 
     /** ----------------------------------------
         Helper
      ---------------------------------------- */
 
     helper() {
         const lightHelper = new THREE.PointLightHelper(this.light, this.distance);
         this.debug && this.scene.add(lightHelper);
     }
 
     /** ----------------------------------------
         Create
      ---------------------------------------- */
 
     create() {
         this.light = new THREE.PointLight(this.color, this.distance, this.decay);
     }
 
 }
 
 /** ----------------------------------------
     Exports
  ---------------------------------------- */
 
 export default Point;