<template>
	<div class="marquee" v-if="text">
        <div class="marquee__text" :class="['marquee__text--' + size, browser]" ref="section">
            <span v-for="_ in times" :key="_">&nbsp; {{ text }}</span>
        </div>
        <component 
            v-bind:is="type"
            class="marquee__svg"
            :class="['marquee__svg--' + size]" 
            :key="_"
        />
    </div>
</template>

<script>
/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _browser from '@/utils/check/_browser';

/** ----------------------------------------
    SVG
 ---------------------------------------- */

import SanderSVG from '@/components/svg/Sander';
import WorkSVG from '@/components/svg/Work';

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Element-Marquee',
    components: {
        SanderSVG,
		WorkSVG
    },
    props: {
        text: String,
        size: {
            default: 'large',
            type: String,
        },
        wheel: Object
    },
    computed: {
        browser: function() {
            return !_browser('chrome');
        },
        times: function() {
            const amount =  Math.round((36 / this.text.length));
            return Array.from({ length: Math.max(amount, 2) }, (_, i) => i);
        },
        type: function() {
            if(!_browser('chrome')) {
                console.log('return type');

                const components = {
                    'Hidding Sander': SanderSVG,
                    'Work': WorkSVG
                };

                return components[this.text];
            }

            return null;
        }
    }
}
</script>