/* ----------------------------------------
    Utils
---------------------------------------- */

import _isType from '../check/_isType';

/* ----------------------------------------
    Circular
---------------------------------------- */

const circular = (id, string, radius) => {
    let origin = 0;

    const el = _isType(id) === 'string' ? document.querySelector(id) : id;
    const text = string.split('');
  
    const deg = 360 / string.length;
  
    text.forEach(letter => {
        const html = `<p style='height: ${ radius }px; position: absolute; transform: rotate(${ origin }deg) translate(-50%, -50%); transform-origin: 0 0'>${ letter }</p>`;
        el.innerHTML += html;
        origin += deg;
    });

    return el;
};

/* ----------------------------------------
    Export
---------------------------------------- */

export default circular;