<template>
	<Loader @load="loaded">
		<Navigation 
			:load="load"
			:wheel="wheel"
			:atTop="atTop"
			:atBottom="atBottom"
			:scrolling="scrolling"
		/>
		<div 
			class="scroll" 
			:class="{ 
				'is-scrolling': scrolling,
				'is-top': atTop,
				'is-bottom': atBottom,
				'is-touch': device !== 'desktop'
			}"
		>
			<div 
				class="scroll__frame"
				:class="[device !== 'desktop' && 'is-touch']"
			>
				<div class="scroll__container" ref="scroll"> 
					<Info
						:load="load"
						:mouse="mouse" 
						:wheel="wheel"
					/>
					<Work />
					<Cases 
						:load="load"
						:mouse="mouse" 
						:wheel="wheel"
					/>
					<Ethics :wheel="wheel" />
					<Footer />
				</div>
			</div>
			<!-- <div class="alert">
				This website is currently being developed, check out the timestamp in the footer for the latest updates. Send me a mail if you find some issue or have a problem.
			</div> -->
		</div>
	</Loader>
	<Cursor :load="load" />
</template>

<script>
/** ----------------------------------------
    Templates
 ---------------------------------------- */

// import Article from './components/Article';
import Cases from './components/Cases';
import Cursor from './components/Cursor';
import Ethics from './components/Ethics';
import Footer from './components/Footer';
import Info from './components/Info';
import Loader from './components/Loader';
import Navigation from './components/Navigation';
import Work from './components/Work';

/** ----------------------------------------
    Mixin
 ---------------------------------------- */

import EventMixin from '@/mixins/Event';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _device from '@/utils/function/_device';
import ticker from '@/utils/function/_ticker';

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'App',
	components: {
		Cases,
		Cursor,
		Ethics,
		Footer,
		Info,
		Loader,
		Navigation,
		Work
	},
	mixins: [
		EventMixin
	],
	data() {
		return {
			load: false,
			scrolling: false,
			atTop: false,
			atBottom: false
		}
	},
	computed: {
		device: function() {
			return _device();
		}
	},
	methods: {
		loaded: function() {
			this.load = true;
		}
	},		
	mounted() {
		const el = this.$refs.scroll;

		if(_device() === 'desktop') {
			ticker(() => {
				if(this.load) {
					this.scrolling = this.wheel.scrolling;

					this.atTop = this.wheel.atTop;
					this.atBottom = this.wheel.atBottom;

					el.style.transform = `translate3d(0px, ${ -this.wheel.yPos }px, 0px)`;
				}
			});
		}

		setTimeout(() => window.dispatchEvent(new Event('resize')), 50);
	}
}
</script>

<style lang='scss'>
	@import './src/styles/style';
</style>
 