/* ----------------------------------------
    Wrap Words
 ---------------------------------------- */

const wrapWords = (el, template) => {
    el.innerHTML = el.innerText.replace(/\w+/g, template || '<span>$&</span>');
};

/* ----------------------------------------
    Export
 ---------------------------------------- */

export default wrapWords;