/** ----------------------------------------
    Browser
 ---------------------------------------- */

const browser = type => {
    const chrome = !!window.chrome && !!window.chrome.webstore || !!window.chrome;
    const edge = !!window.StyleMedia;
    const firefox = typeof InstallTrigger !== 'undefined';
    const safari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    const browsers = {
        chrome, 
        edge,
        firefox,
        safari
    };

    return browsers[type] || null;
}

/** ----------------------------------------
    Export
 ---------------------------------------- */

export default browser;