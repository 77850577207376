/** ----------------------------------------
    Three JS
---------------------------------------- */

import * as THREE from 'three';

/** ----------------------------------------
    Config
---------------------------------------- */

const defaultConfig = {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
    radius: 0.25
};

/** ----------------------------------------
    Rounded Plane
---------------------------------------- */

const roundedPlane = (config) => {
    const { x, y, width, height, radius } = { ...defaultConfig, ...config };

    const shape = new THREE.Shape();

    shape.moveTo(x, y + radius);
    shape.lineTo(x, y + height - radius);
    shape.quadraticCurveTo( x, y + height, x + radius, y + height);
    shape.lineTo(x + width - radius, y + height);
    shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
    shape.lineTo(x + width, y + radius);
    shape.quadraticCurveTo(x + width, y, x + width - radius, y);
    shape.lineTo(x + radius, y);
    shape.quadraticCurveTo(x, y, x, y + radius);

    return new THREE.ShapeBufferGeometry(shape);
};

/** ----------------------------------------
    Export
---------------------------------------- */

export default roundedPlane;