<template>  
    <svg width="540px" height="540px" viewBox="0 0 540 540" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="spotify" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M270.498892,85 C168.052858,85 85,168.052626 85,270.501108 C85,372.954019 168.052858,456 270.498892,456 C372.956003,456 456,372.954019 456,270.501108 C456,168.059272 372.956003,85.0088606 270.496677,85.0088606 L270.498892,85 Z M355.567489,352.54584 C352.244843,357.99511 345.11223,359.722927 339.66309,356.37805 C296.109847,329.774096 241.281759,323.748888 176.711673,338.501788 C170.489465,339.919484 164.287192,336.02082 162.86953,329.796248 C161.445223,323.571676 165.328288,317.369255 171.566002,315.951559 C242.227606,299.800899 302.839313,306.758686 351.735371,336.641062 C357.18451,339.985939 358.912286,347.096571 355.567489,352.54584 L355.567489,352.54584 Z M378.272236,302.02934 C374.085702,308.840927 365.181011,310.989623 358.380662,306.802989 C308.518822,276.147525 232.512189,267.271419 173.535224,285.174262 C165.886493,287.484664 157.808033,283.173982 155.486611,275.538359 C153.18291,267.889445 157.495704,259.826299 165.131145,257.500391 C232.498898,237.058985 316.249512,246.960706 373.509777,282.148367 C380.310126,286.335001 382.45877,295.239905 378.272236,302.031555 L378.272236,302.02934 Z M380.221522,249.432814 C320.436046,213.921741 221.797763,210.65661 164.716921,227.9813 C155.550849,230.761313 145.857583,225.586722 143.079851,216.420431 C140.302119,207.249709 145.472156,197.562857 154.644874,194.776198 C220.169667,174.884149 329.097076,178.727435 397.929009,219.590311 C406.191322,224.483577 408.893741,235.131804 403.998376,243.365518 C399.125162,251.610307 388.448393,254.328296 380.230382,249.432814 L380.221522,249.432814 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Spotify-Icon'
    }
</script>