/** ----------------------------------------
    Three JS
---------------------------------------- */

import * as THREE from 'three';

/** ----------------------------------------
    Axes
---------------------------------------- */

const axesHelper = ({ scene, debug }) => {
    const axes = new THREE.AxesHelper(30);
    debug && scene.add(axes);
};

/** ----------------------------------------
    Exports
---------------------------------------- */

export default axesHelper;