<template>
    <section class="cases" data-action="null" ref="section">
        <Marquee :class="'cases__marquee'" :text="'Work'" :size="'small'" />
        <div class="cases__image">
            <img src="@/assets/images/mocks.png"/>
        </div>
        <!-- <Range 
            :class="'cases__range'"
            :mouse="mouse"
        /> -->
    </section>
</template>

<script>
/** ----------------------------------------
    3D
 ---------------------------------------- */

import render from '@/3d/render';

/** ----------------------------------------
    Components
 ---------------------------------------- */

import Marquee from './Marquee';

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Section-Mock',
    data() {
        return {
            active: false,
            items: [
                {
                    name: 'Cinekid',
                    color: '#102C7A',
                }
            ],
            reveal: false,
            render: null
        }
    },
    components: {
        Marquee
    },
    props: {
        load: {
            type: Boolean,
            default: false
        },
        mouse: Object,
        wheel: Object
    },
    watch: {
        load() {
            const el = this.$refs.section;

            this.render = render(el, {
                mouse: this.mouse,
                wheel: this.wheel
            }, this.reveal);
        }
    },
    methods: {
        rotate: function(el) {
            el.style.transform = `translate(${ (this.mouse.xPos * 0.02)}px, ${ this.wheel.yPos * -0.1 }px) rotate(${ (this.mouse.xPos * 0) }deg)`;
        }
    }
}
</script>