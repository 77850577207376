<template>
	<section class="work" id="work" ref="section">
        <div class="work__container">
            <a 
                class="work__item grid" 
                v-for="(item, index) in items" 
                data-action="visit"
                target="_blank"
                ref="item"
                :href="item.url"
                :key="index"
            >
                <span class="work__year">{{ item.years }}</span>
                <span class="work__company">
                    {{ item.company }}, {{ item.location }}
                    <span class="work__active" v-if="item.active">Current</span>
                    <p class="work__note" v-if="item.note">{{ item.note }}</p>
                </span>
                <span class="work__roles">
                    <p class="work__role" v-for="role in item.roles" :key="role">{{ role }}</p>
                </span>
            </a>
            <div class="work__footer grid">
                <p class="work__collaboration">WORKED WITH DUTCH DESIGN WEEK, HARTSTICHTING, LITERATUURMUSEUM, FOTOMUSEUM DEN HAAG, THEATER ROTTERDAM, CINEKID, ANP, TEXTIELMUSEUM, VEILIG THUIS, MUSEUMNACHT AMSTERDAM, RIJKSOVERHEID AND MANY MORE...</p>
                <div class="work__button">
                    <a 
                        class="btn--white" 
                        href="https://www.linkedin.com/in/sanderhidding/" 
                        data-action="pulse"
                        target="_blank"
                    >
                        View more, visit Linkedin
                    </a>
                </div>
            </div> 
        </div>
    </section>
</template>

<script>
/** ----------------------------------------
    Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Utils
 ---------------------------------------- */

import _bounds from '../utils/function/_bounds';

/** ----------------------------------------
    Component
 ---------------------------------------- */

export default {
	name: 'Section-Work',
    data() {
        return {
            completed: false,
            items: [
                {
                    active: true,
                    years: '20/22',
                    company: 'Greenberry',
                    location: 'Utrecht',
                    url: 'https://greenberry.nl/',
                    roles: [
                        'Digital Strategy',
                        'Business Developer',
                        'Development'
                    ]
                },
                {
                    active: true,
                    years: '18/22',
                    company: 'HackYourFuture',
                    location: 'Amsterdam',
                    note: 'Volunteer',
                    url: 'https://www.hackyourfuture.net/',
                    roles: [
                        'Mentor'
                    ]
                },
                {
                    active: false,
                    years: '17/20',
                    company: 'Verve',
                    location: 'The Hague',
                    url: 'https://verveagency.com/',
                    note: 'Formally known as Vruchtvlees: Digital, Strategy & Design',
                    roles: [
                        'Digital Producer',
                        'Tech Lead',
                        'Frontend Development'
                    ]
                },
                {
                    active: false,
                    years: '14/17',
                    company: 'Mediavisie',
                    location: 'Deventer',
                    url: 'https://mediavisie.nl/',
                    roles: [
                        'Media Developer'
                    ]
                }
            ],
            reveal: false,
            ticker: null,
        }
    },
    watch: {
        reveal() {
            if(this.reveal && !this.completed) {
                const items = this.$refs.item;

                anime({
                    targets: items,
                    translateY: [50, 0],
                    scale: [1.1, 1],
                    opacity: [0, 1],
                    delay: anime.stagger(175, {
                        start: 400
                    }),
                    duration: 500,
                    easing: 'easeOutCirc',
                });

                this.completed = true;
            }
        }
    },
    mounted() {
        const el = this.$refs.section;

        this.ticker = _bounds(el, data => {
            this.reveal = data.inView;
        });
    }
}
</script>