/** ----------------------------------------
    Three JS
---------------------------------------- */

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

/** ----------------------------------------
    Node Modules
---------------------------------------- */

import anime from 'animejs';
import throttle from 'lodash/throttle';

/** ----------------------------------------
    Debug
---------------------------------------- */

const debug = false;

const config = {
    controls: false
};

/** ----------------------------------------
    Three Objects
---------------------------------------- */

let camera,
    scene,
    renderer;

/** ----------------------------------------
    Viewport
---------------------------------------- */

import { view } from '@/utils/function/_view';

/** ----------------------------------------
    Helpers
---------------------------------------- */

import axesHelper from '@/3d/helpers/axes';

/** ----------------------------------------
    Lights
---------------------------------------- */

import Ambient from '@/3d/lights/Ambient';
import Point from '@/3d/lights/Point';

/** ----------------------------------------
    Scene
---------------------------------------- */

import Phone from '@/3d/scene/Phone';
import Tablet from '@/3d/scene/Tablet';

/** ----------------------------------------
    Events
---------------------------------------- */

// import mouseEvent from '@/utils/function/_mouse';
// import wheelEvent from '@/utils/function/_wheel';
import ticker from '@/utils/function/_ticker';

/** ----------------------------------------
    Initialize
---------------------------------------- */

var models;
var raycaster;

let mouse = {
    x: 0,
    y: 0
}

const init = (canvas, e, active) => {

    const viewData = view();

    /** ----------------------------------------
        Scene
    ---------------------------------------- */

    scene = new THREE.Scene();

    /** ----------------------------------------
        Renderer
    ---------------------------------------- */

    const renderOptions = { 
        alpha: true 
    };

    renderer = new THREE.WebGLRenderer(renderOptions);

    /** ----------------------------------------
        Config Canvas
    ---------------------------------------- */

    renderer.setClearColor(new THREE.Color(0x000000));

    /** ----------------------------------------
        Config Camera
    ---------------------------------------- */

    camera = new THREE.PerspectiveCamera(45, viewData.width / viewData.height, 0.1, 2000);
    
    camera.position.set(-10, 0, 0);
    camera.lookAt(0, 0, 0);

    /** ----------------------------------------
        Camera Animation
    ---------------------------------------- */

    // const cameraPos = {
    //     x: -9,
    //     y: 0,
    //     z: 0
    // };

    // const cameraAnimation = () => anime({
    //     targets: cameraPos,
    //     x: -10,
    //     y: 1,
    //     z: 0,
    //     duration: 3500,
    //     easing: 'easeOutSine'
    // });

    // cameraAnimation();

    /** ----------------------------------------
        World
    ---------------------------------------- */

    const world = {
        camera,
        scene,
        renderer,
        debug
    };

    /** ----------------------------------------
        Axes
    ---------------------------------------- */

    axesHelper(world);

    /** ----------------------------------------
        Canvas
    ---------------------------------------- */

    canvas.appendChild(renderer.domElement);

    /** ----------------------------------------
        Render
    ---------------------------------------- */

    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    renderer.shadowMap.enabled = true;

    /** ----------------------------------------
        Ambient Light
    ---------------------------------------- */

    const ambientConfig = {
        color: 0xFFFFFF,
        intensity: 2,
        position: {
            x: -10,
            y: 0,
            z: 8
        },
        shadow: true,
    };

    new Ambient({
        ...world,
        ...ambientConfig
    });

    /** ----------------------------------------
        Point Light
    ---------------------------------------- */

    const generalPointConfig = {
        color: 0x6F9B91,
        distance: 2,
        decay: 2,
        intensity: 2,
        shadow: true,
    };

    const leftPointConfig = {
        position: {
            x: -2,
            y: 0,
            z: 5
        }
    };

    new Point({
        ...world,
        ...generalPointConfig,
        ...leftPointConfig
    });

    const rightPoint = {
        position: {
            x: -2,
            y: 0,
            z: -5
        }
    };

    new Point({
        ...world,
        ...generalPointConfig,
        ...rightPoint
    });

    /** ----------------------------------------
        Phone
    ---------------------------------------- */

    const tabletConfig = {
        color: 0x000000,
        video: 'fojm',
        shadow: true
    };

    const tablet = new Tablet({
        ...tabletConfig,
        ...world
    });

    tablet.model.rotation.y = Math.PI * 1.25;
    tablet.model.rotation.x = 0.25
    tablet.model.rotation.z = 0.5;

    tablet.model.position.x = 2.5;
    tablet.model.position.z = -2.5;

    const phoneConfig = {
        color: 0x000000,
        image: './media/screenshot.png',
        shadow: true
    };

    const phone = new Phone({
        ...phoneConfig,
        ...world
    });

    phone.model.rotation.y = Math.PI * -3.25;
    phone.model.rotation.x = -0.15
    phone.model.rotation.z = 0.25;

    phone.model.position.y = 2;
    phone.model.position.x = 1.5;
    phone.model.position.z = 3;

    const buildersConfig = {
        color: 0x000000,
        video: 'builders',
        shadow: true
    };

    const builders = new Tablet({
        ...buildersConfig,
        ...world
    });

    builders.model.rotation.y = Math.PI * -1.2;
    builders.model.rotation.x = 0.25
    builders.model.rotation.z = 0;

    builders.model.position.y = 1;
    builders.model.position.x = 3;
    builders.model.position.z = 9;

    /** ----------------------------------------
        Controls
    ---------------------------------------- */

    let controls = null;

    if(config.controls) {
        controls = new OrbitControls(camera, renderer.domElement);
        controls.update();
    }

    /** ----------------------------------------
        Render
    ---------------------------------------- */

    let i = 0;

    // let deviceConfig = {
    //     x: Math.PI * 3,
    //     y: Math.PI,
    //     z: 0.025 * Math.PI
    // }

    // const deviceAnimation = () => {
    //     anime({
    //         targets: deviceConfig,
    //         // x: [Math.PI, Math.PI * 4],
    //         y: Math.PI * 3,
    //         z: [0.025 * Math.PI, (0.025 * Math.PI) * -4],
    //         duration: 2750,
    //         easing: 'easeInOutExpo'
    //     });
    // }

    // const deviceAnimation = mouse => {
    //     deviceConfig.x += Math.PI * 1
    // }

    // let active = false;
    
    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();
    
    // canvas.addEventListener('mousemove', e => {
    //     mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    //     mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

    //     raycaster.setFromCamera(mouse, camera);

    //     const intersects = raycaster.intersectObject(scene, true);
      
    //     if (intersects.length > 0) {
    //         models = intersects;

    //         if(active) return;

    //         models.forEach(model => {
    //             if(!model.object.group) return;

    //             !active && anime({
    //                 targets: model.object.group.rotation,
    //                 y: [Math.PI * 1.25, Math.PI],
    //                 z: [0.5, 0],
    //                 easing: 'easeOutExpo',
    //                 begin: () => active = true,
    //                 complete: () => active = false
    //             })
    //         })

    //         // active = true;
    //     } else {
    //         // console.log('outside');
    //     }  
    // });

//     canvas.addEventListener('click', e => {
//         e.preventDefault();

//         let active = false;
// e
//         mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
//         mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

//         raycaster.setFromCamera(mouse, camera);

//         const intersects = raycaster.intersectObject(scene, true);
      
//         if (intersects.length > 0) {
//             models = intersects;

//             models.forEach(model => {
//                 !active && anime({
//                     targets: model.object.group.rotation,
//                     y: [Math.PI * 1.25, Math.PI],
//                     z: [0.5, 0],
//                     easing: 'easeOutExpo',
//                     // complete: () => active = false,
//                 })
//             })
//         } else {
//             console.log('outside');
//         }   
//     });

    // camera.rotateZ(Math.PI * 150 * 0.001);
    // camera.rotateX(Math.PI * -50 * 0.001);

    ticker(() => {
        // console.log(active);
        // if(!active) return;

        renderer.render(scene, camera);

        if(tablet.model) {
            // phone.model.rotation.y = (Math.PI * (i * 0.15)) + 2;
            // phone.model.rotation.y = Math.PI;
            // tablet.model.rotation.z = deviceConfig.z;
            // phone.model.rotation.x = deviceConfig.x;
            // tablet.model.rotation.x = (0.025 * Math.sin(i * 0.3)) * Math.PI;
            // phone.model.rotation.z = 0.05 * Math.PI;
            // phone.model.rotation.z = deviceConfig.z;

            // tablet.model.updateMatrix();
        }

        // camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);

        config.controls && controls.update();

        const pos = (viewData.width * 0.5 - e.mouse.xPos) * -0.002;
        const scroll = (viewData.width * 0.5 - e.wheel.yPos) * -0.0001;

        // camera.position.x = -10 + Math.abs(pos);
        camera.position.z = pos + 3;

        // tablet.model.rotation.y = Math.PI * (15 * scroll * 0.5);
        tablet.model.rotation.y = Math.PI * (e.mouse.xPos * 0.00025 + 0.75);
        
        phone.model.rotation.y = Math.PI * (15 * scroll * 0.5);
        phone.model.position.y = 2 + (Math.sin(i * 0.5) * 0.1);

        builders.model.rotation.x = Math.PI * (scroll * 0.3 + 2) + Math.sin(i * 0.25) * 0.1;
        builders.model.rotation.z = Math.PI * (e.mouse.xPos * 0.000075);

        tablet.model.position.y = (Math.sin(i * 0.025));

        i += 0.025;
    });

    // return {
    //     deviceAnimation
    // }

};

/** ----------------------------------------
    Resize
---------------------------------------- */

const resize = () => {
    if(!camera) return;
    const viewData = view();
    camera.aspect = viewData ? viewData.width / viewData.height : 0;
    camera.updateProjectionMatrix();
    renderer.setSize(viewData.width, viewData.height);
};

window.addEventListener('resize', throttle(resize, 100));

/** ----------------------------------------
    Exports
---------------------------------------- */

export default init;