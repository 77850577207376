/** ----------------------------------------
    Package
 ---------------------------------------- */

import { createApp } from 'vue';

/** ----------------------------------------
    Components
 ---------------------------------------- */

import App from './App.vue';

/** ----------------------------------------
    Initalize
 ---------------------------------------- */

createApp(App).mount('#app');