<template>
    <svg width="540px" height="540px" viewBox="0 0 540 540" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="github" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M270.5,87 C166.90625,87 83,170.963876 83,274.628773 C83,357.654505 136.671875,427.780759 211.203125,452.641572 C220.578125,454.283324 224.09375,448.65446 224.09375,443.729205 C224.09375,439.273022 223.859375,424.497256 223.859375,408.783346 C176.75,417.461177 164.5625,397.291084 160.8125,386.736965 C158.703125,381.342638 149.5625,364.690584 141.59375,360.234401 C135.03125,356.716362 125.65625,348.038531 141.359375,347.803995 C156.125,347.569459 166.671875,361.407081 170.1875,367.035944 C187.0625,395.414796 214.015625,387.440573 224.796875,382.515318 C226.4375,370.319448 231.359375,362.110689 236.75,357.419969 C195.03125,352.72925 151.4375,336.546268 151.4375,264.778263 C151.4375,244.373634 158.703125,227.487044 170.65625,214.35303 C168.78125,209.662311 162.21875,190.430361 172.53125,164.631405 C172.53125,164.631405 188.234375,159.70615 224.09375,183.863354 C239.09375,179.641707 255.03125,177.530883 270.96875,177.530883 C286.90625,177.530883 302.84375,179.641707 317.84375,183.863354 C353.703125,159.471614 369.40625,164.631405 369.40625,164.631405 C379.71875,190.430361 373.15625,209.662311 371.28125,214.35303 C383.234375,227.487044 390.5,244.139098 390.5,264.778263 C390.5,336.780804 346.671875,352.72925 304.953125,357.419969 C311.75,363.283369 317.609375,374.541095 317.609375,392.131293 C317.609375,417.226641 317.375,437.396734 317.375,443.729205 C317.375,448.65446 320.890625,454.51786 330.265625,452.641572 C404.328125,427.780759 458,357.419969 458,274.628773 C458,170.963876 374.09375,87 270.5,87 Z" id="Path" fill="#FFFFFF"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Github-Icon'
    }
</script>