/** ----------------------------------------
    Three JS
 ---------------------------------------- */

 import * as THREE from 'three';

 /** ----------------------------------------
     Ambient
  ---------------------------------------- */
 
 class Ambient {
 
     constructor(config) {
         this.values(config);
         this.create();
         this.apply();
     }
 
     /** ----------------------------------------
         Values
      ---------------------------------------- */
 
     values(config) {
         Object.keys(config).forEach(setting => {
             this[setting] = config[setting];
         });
     }
 
     /** ----------------------------------------
         Apply
      ---------------------------------------- */
 
     apply() {
         const pos = this.position;
 
         this.light.position.set(pos.x, pos.y, pos.z);
         this.light.intensity = this.intensity;
 
         this.scene.add(this.light);
     }
 
     /** ----------------------------------------
         Create
      ---------------------------------------- */
 
     create() {
         this.light = new THREE.AmbientLight(this.color);
     }
 
 }
 
 /** ----------------------------------------
     Exports
  ---------------------------------------- */
 
 export default Ambient;